import Navs from './pages/nav/nav';
import './App.css';
import Tips from './pages/tip/tip';
import Page1 from './pages/page1';
import Bottom from './pages/bottom/bottom';


function App() {
  return (
    <div className="App">
      <div className="content">
        <Navs></Navs>
        <Tips></Tips>
        <Page1></Page1>
      </div>
      
      <Bottom></Bottom>
    </div>
  );
}

export default App;
