
import './nav.css';
function Navs(props){
    return (
        <div className="topBox">
            <div className="navBox flex col-fff">
                <img className="logo" src="https://sp.ipangsell.com/xcx/icon/icon-logo.png" alt="logo" />
                <p className="headerTit fontBold">60万购房者关注的专业自媒体平台</p>
            </div>
        </div>
    )
}

export default Navs