
import './tip.css';
import pangImg from '../../assets/xiao-pang.png';
function Tips(props){
    return (
        <div className="tipsWrap flex">
            <img className="pangImg" src={pangImg} alt="pang" />
            <div className="tipTxt text-left">
                <p>亲爱的用户：</p>
                <p className="top-8">为了在移动互联网时代给大家带来更便捷的体验。您可扫描下方二维码，进小胖看房团微信公众号或者微信小程序查看新房信息、批次名单、热门项目价格、摇号结果开盘时间、政策解读等!</p>
                <p className="top-8">小胖看房团是60万购房者关注的专业自媒体平台。</p>
            </div>
        </div>
    )
}

export default Tips